.side_nav_item {
    a {
      white-space: nowrap;
      margin-bottom: 5px;
    }
  }
  .underline{
    text-decoration: underline;
  }
  .bold_18{
    font-size: 20px;
  }
  .bold_20{
    font-size: 20px;
    font-weight: bold;
  }