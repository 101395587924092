.app_map_container {
  width: 70%;
  height: 600px;
  .leaflet-container {
    width: 70%;
    height: 600px;
    z-index: 9 !important;
    border-radius: 20px;
  }

  @media screen and(max-width:1071px) {
    .leaflet-container {
      width: 67%;
    }
  }
  @media screen and(max-width:992px) {
    .leaflet-container {
      width: 62%;
    }
  }

  @media screen and(max-width:900px) {
    .leaflet-container {
      width: 56%;
    }
  }

  @media screen and(max-width:820px) {
    .leaflet-container {
      width: 52%;
    }
  }

  @media screen and(max-width:767px) {
    .leaflet-container {
      width: 95%;
    }
  }

  @media screen and(max-width:500px) {
    .leaflet-container {
      width: 100%;
    }
  }
}
.map_modal {
  height: 550px;
  max-height: 100vh;
  .leaflet-container {
    width: 100%;
    height: 400px;
    max-height: 65vh;
    z-index: 9 !important;
    border-radius: 16px;
  }
}
