.scale-right {
  animation: scaleRight 0.05s ease !important;
  transform-origin: 0% 50%;
}
@keyframes scaleRight {
  0% {
    transform: scaleX(0.85);
    color: transparent;
    opacity: 0;
  }
  100% {
    color: #fff;
    transform: scaleX(1);
    opacity: 1;
  }
}
.file-progress-bar{
  transition: all 0.3 ease;
  transform-origin: 0% 50%;
  height: 10px;
  border-radius: 5px;
  &.empty{
    transform: scaleX(0);
  }
  &.half{
    transform: scaleX(0.5);
  }
  &.full{
    transform: scaleX(1);
  }
}
