.layout {
	.nav_links a {
		text-decoration: none;
		color: black;
	}

	.active_nav {
	}
}

.small_content {
	display: flex;
	padding: 2rem;
}
.info_important_cokitchen p{
    margin:0.3rem 1rem 0.3rem .5rem;
    text-align: left;
}
.info_important_cokitchen h5{
    margin-top:.6em;
    text-align: left;
	height: 2em;
}
.info_important_cokitchen{
    background-color: rgba(247, 202, 167, 0.866);
  border-radius: 8px;
    color:black;
    font-size: 12px;
    text-align: left;
    font-weight: regular;
    padding-top: .1em;
    padding-bottom: .1em;
	padding-left: 10px;
    display:inline-block
   
}
.mt-4{ margin-top: -4.0em!important;}

