.open_pallete {
	margin-top: 0.5em;
	text-decoration: underline;
	color: black;
	font-size: 12px;
	font-weight: bold;
	cursor: pointer;
	padding-left: 0.7em;
}

.feed-flex {
	display: flex;
	flex-wrap: wrap;
	gap: 2rem;
	width: 100%;
	padding: 3rem;
}

.content_section {
	width: 100%;
	.cards_container {
		width: 100%;
	}
}

.feed-loader {
	padding: 3rem;
}
