/** @format */

@import "config";
//Presets
* {
	box-sizing: border-box;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-kerning: auto;

	margin: 0;
}
html,
#root,
body {
	height: 100%;
	font-family: Arial, Helvetica, sans-serif;
	background-color: $primary-white;
}
html {
	overflow: auto;
	background-color: $primary-white;
	font-family: sans-serif;
	-webkit-text-size-adjust: 100%;
}
body {
	background-color: $primary-white;
	margin: 0;
	padding: 0;
}
// ::-webkit-scrollbar {
//   width: 0px !important;
// }
button {
	background: none;
	border: none;
	&:disabled {
		opacity: 0.5;
		cursor: not-allowed;
		h6 {
			cursor: not-allowed;
		}
		&:hover,
		&:active,
		&:focus {
			background: none !important;
		}
	}
}
button:hover,
button:active,
button:focus {
	outline: none;
	border: none;
}

textarea:hover,
textarea:active,
textarea:focus {
	outline: none;
}
textarea {
	&::placeholder {
		color: $light-gray;
		@include fluid-type($min_width, $max_width, $min_font_18, $max_font_18);
	}
}

.react-select__placeholder {
	color: $light-gray !important;
	@include fluid-type($min_width, $max_width, $min_font_18, $max_font_18);
}
.react-select__control {
	border: 2px solid $light-gray !important;
	&:hover,
	&:focus,
	&:active {
		border: 2px solid$primary-blue !important;
		outline: none !important;
	}
}
// Fonts and Texts
.capitalize {
	text-transform: capitalize;
}
.red_normal_20px {
	color: $primary-red;
	@include fluid-type($min_width, $max_width, $min_font_20, $max_font_20);
}
.bold_white_23 {
	color: $primary-white;
	@include fluid-type($min_width, $max_width, $min_font_20, $max_font_20);
}
.bold_18_px {
	@include fluid-type($min_width, $max_width, $min_font_18, $max_font_18);
}
.bold_18_px,
.bold_19px,
.bold_21px,
.bold_24px {
	color: $primary-black;
	font-weight: bold;
}
.bold_19px {
	@include fluid-type($min_width, $max_width, $min_font_19, $max_font_19);
}
.bold_24px {
	@include fluid-type($min_width, $max_width, $min_font_24, $max_font_24);
}
.bold_21px {
	@include fluid-type($min_width, $max_width, $min_font_18, $max_font_18);
}
.normal_18px {
	color: $primary-black;
	@include fluid-type($min_width, $max_width, $min_font_18, $max_font_18);
	font-weight: normal;
}

.normal_17px {
	color: $primary-black;
	@include fluid-type($min_width, $max_width, $min_font_16, $max_font_16);
	font-weight: normal;
}
.normal_16px {
	color: $primary-black;
	@include fluid-type($min_width, $max_width, $min_font_16, $max_font_16);
	font-weight: normal;
}
.normal_14px {
	@include fluid-type($min_width, $max_width, $min_font_14, $max_font_14);
}

.normal_15px {
	@include fluid-type($min_width, $max_width, $min_font_14, $max_font_14);
}
.gray {
	color: $primary-gray !important;
}
.black {
	color: $primary-black !important;
}
.underlined {
	text-decoration: underline;
}

.no_decor {
	text-decoration: none;
}
.ellipses_end {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 1; /* number of lines to show */
	-webkit-box-orient: vertical;
}
.ellipses_end_two {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2; /* number of lines to show */
	-webkit-box-orient: vertical;
}
.gray_label {
	@include fluid-type($min_width, $max_width, $min_font_18, $max_font_18);
	color: $primary-gray;
}
.gray_label_sec {
	@include fluid-type($min_width, $max_width, $min_font_18, $max_font_18);
	color: $secondary-gray;
}
.text_center {
	text-align: center;
}

.text_left {
	text-align: left !important;
}
.bold {
	font-weight: bold;
}
.normal {
	font-weight: normal !important;
}

// Displays
.column {
	display: flex;
	flex-direction: column;
}
.flex_column {
	@include d_flex(column, none, none);
}

.flex_column_center {
	@include d_flex(column, center, center);
}
.flex_column_end {
	@include d_flex(column, center, flex-end);
}
.flex_column_right {
	@include d_flex(column, flex-end, center);
}
.flex_column_left {
	@include d_flex(column, flex-start, center);
}
.flex_column_start {
	@include d_flex(column, center, flex-start);
}
.flex_column_left_top {
	@include d_flex(column, flex-start, flex-start);
}
.flex_column_between {
	@include d_flex(column, space-between, flex-start);
}
.flex_row {
	@include d_flex(row, none, none);
}
.flex_row_end {
	@include d_flex(row, center, flex-end);
}

.flex_row_left {
	@include d_flex(row, center, flex-start);
}

.flex_row_start {
	@include d_flex(row, flex-start, flex-start);
}
.flex_row_center {
	@include d_flex(row, center, center);
}
.flex_row_align_center {
	@include d_flex(row, center, flex-start);
}
.flex_row_between {
	@include d_flex(row, center, space-between);
}
.flex_row_top {
	@include d_flex(row, flex-start, space-between);
}
.flex_row_center_top {
	@include d_flex(row, flex-start, center);
}
.flex_row_bottom {
	@include d_flex(row, flex-end, space-between);
}

.align_left {
	align-items: flex-start !important;
}
.justify_start {
	justify-content: flex-start !important;
}

.grid_third {
	display: grid;
	place-content: center;

	grid-gap: 15px;
	grid-template-columns: repeat(3, minmax(0, 1fr));
	@media screen and(min-width:1300px) {
		grid-template-columns: repeat(4, minmax(0, 1fr));
		grid-auto-columns: minmax(0, 1fr);
	}
	@media screen and(max-width:1299px) {
		grid-template-columns: repeat(3, minmax(0, 1fr));
		grid-auto-columns: minmax(0, 1fr);
	}
	@media screen and(max-width:1028px) {
		grid-template-columns: repeat(2, minmax(0, 1fr));
		grid-auto-columns: minmax(0, 1fr);
	}

	@media screen and(max-width:440px) {
		grid-template-columns: repeat(1, minmax(0, 1fr));
		grid-auto-columns: minmax(0, 1fr);
	}
}

.grid_third_two {
	display: grid;
	place-content: center;
	grid-gap: 0.75rem;
	grid-template-columns: repeat(3, minmax(0, 1fr));
	@media screen and(min-width:1300px) {
		grid-template-columns: repeat(3, minmax(0, 1fr));
		grid-auto-columns: minmax(0, 1fr);
	}
	@media screen and(max-width:1299px) {
		grid-template-columns: repeat(3, minmax(0, 1fr));
		grid-auto-columns: minmax(0, 1fr);
	}
	@media screen and(max-width:1028px) {
		grid-template-columns: repeat(2, minmax(0, 1fr));
		grid-auto-columns: minmax(0, 1fr);
	}

	@media screen and(max-width:440px) {
		grid-template-columns: repeat(1, minmax(0, 1fr));
		grid-auto-columns: minmax(0, 1fr);
	}
}

.grid_fifth {
	display: grid;
	place-content: center;
	grid-gap: 0.4rem;
	grid-template-columns: repeat(5, minmax(0, 1fr));
	grid-auto-flow: row;
	@media screen and(min-width:1300px) {
		grid-template-columns: repeat(6, minmax(0, 1fr));
		grid-auto-columns: minmax(0, 1fr);
	}
	@media screen and(max-width:1299px) {
		grid-template-columns: repeat(5, minmax(0, 1fr));
		grid-auto-columns: minmax(0, 1fr);
	}
	@media screen and(max-width:1028px) {
		grid-template-columns: repeat(4, minmax(0, 1fr));
		grid-auto-columns: minmax(0, 1fr);
	}

	@media screen and(max-width:440px) {
		grid-template-columns: repeat(3, minmax(0, 1fr));
		grid-auto-columns: minmax(0, 1fr);
	}
}

.grid_second {
	display: grid !important;
	place-content: top;
	grid-gap: 2rem;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	grid-auto-flow: row;
	align-items: flex-start !important;
	@media screen and(min-width:1300px) {
		grid-template-columns: repeat(2, minmax(0, 1fr));
		grid-auto-columns: minmax(0, 1fr);
	}
	@media screen and(max-width:1299px) {
		grid-template-columns: repeat(2, minmax(0, 1fr));
		grid-auto-columns: minmax(0, 1fr);
	}
	@media screen and(max-width:735px) {
		grid-template-columns: repeat(1, minmax(0, 1fr));
		grid-auto-columns: minmax(0, 1fr);
	}
}

.grid_second_two {
	display: grid !important;
	place-content: top;
	grid-gap: 0.1rem;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	grid-auto-flow: row;
	align-items: flex-start !important;
	@media screen and(min-width:1300px) {
		grid-template-columns: repeat(2, minmax(0, 1fr));
		grid-auto-columns: minmax(0, 1fr);
	}
	@media screen and(max-width:1299px) {
		grid-template-columns: repeat(2, minmax(0, 1fr));
		grid-auto-columns: minmax(0, 1fr);
	}
	@media screen and(max-width:735px) {
		grid-template-columns: repeat(1, minmax(0, 1fr));
		grid-auto-columns: minmax(0, 1fr);
	}
}

//Spacing
.m_25 {
	@include fluid-margin(
		$min_width,
		$max_width,
		$min_margin_25,
		$max_margin_25,
		$min_margin_25,
		$max_margin_25,
		$min_margin_25,
		$max_margin_25,
		$min_margin_25,
		$max_margin_25
	);
}
.mt_25 {
	@include fluid-margin(
		$min_width,
		$max_width,
		$min_margin_25,
		$max_margin_25,
		0px,
		0px,
		0px,
		0px,
		0px,
		0px
	);
}

.mt_10 {
	@include fluid-margin(
		$min_width,
		$max_width,
		$min_margin_10,
		$max_margin_10,
		0px,
		0px,
		0px,
		0px,
		0px,
		0px
	);
}

.mr_10 {
	@include fluid-margin(
		$min_width,
		$max_width,
		0px,
		0px,
		$min_margin_10,
		$max_margin_10,
		0px,
		0px,
		0px,
		0px
	);
}
.mr_15 {
	@include fluid-margin(
		$min_width,
		$max_width,
		0px,
		0px,
		$min_margin_15,
		$max_margin_15,
		0px,
		0px,
		0px,
		0px
	);
}
.mr_20 {
	margin-right: 20px;
}
.mr_25 {
	@include fluid-margin(
		$min_width,
		$max_width,
		0px,
		0px,
		$min_margin_25,
		$max_margin_25,
		0px,
		0px,
		0px,
		0px
	);
}
.mr_30 {
	@include fluid-margin(
		$min_width,
		$max_width,
		0px,
		0px,
		$min_margin_30,
		$max_margin_30,
		0px,
		0px,
		0px,
		0px
	);
}
.mr_45 {
	@include fluid-margin(
		$min_width,
		$max_width,
		0px,
		0px,
		$min_margin_45,
		$max_margin_45,
		0px,
		0px,
		0px,
		0px
	);
}
.mb_4 {
	@include fluid-margin(
		$min_width,
		$max_width,
		0px,
		0px,
		0px,
		0px,
		2px,
		3px,
		0px,
		0px
	);
}

.mb_5 {
	@include fluid-margin(
		$min_width,
		$max_width,
		0px,
		0px,
		0px,
		0px,
		3px,
		5px,
		0px,
		0px
	);
}

.mb_12 {
	@include fluid-margin(
		$min_width,
		$max_width,
		0px,
		0px,
		0px,
		0px,
		$min_margin_10,
		$max_margin_10,
		0px,
		0px
	);
}
.mb_15 {
	@include fluid-margin(
		$min_width,
		$max_width,
		0px,
		0px,
		0px,
		0px,
		$min_margin_15,
		$max_margin_15,
		0px,
		0px
	);
}

.mb_30 {
	@include fluid-margin(
		$min_width,
		$max_width,
		0px,
		0px,
		0px,
		0px,
		$min_margin_30,
		$max_margin_30,
		0px,
		0px
	);
}

.mb_25 {
	@include fluid-margin(
		$min_width,
		$max_width,
		0px,
		0px,
		0px,
		0px,
		$min_margin_25,
		$max_margin_25,
		0px,
		0px
	);
}

.mb_35 {
	@include fluid-margin(
		$min_width,
		$max_width,
		0px,
		0px,
		0px,
		0px,
		$min_margin_35,
		$max_margin_35,
		0px,
		0px
	);
}

.mb_45 {
	@include fluid-margin(
		$min_width,
		$max_width,
		0px,
		0px,
		0px,
		0px,
		$min_padding_50,
		$max_padding_50,
		0px,
		0px
	);
}
.mt_35 {
	@include fluid-margin(
		$min_width,
		$max_width,
		$min_margin_35,
		$max_margin_35,
		0px,
		0px,
		0px,
		0px,
		0px,
		0px
	);
}

.ml_10 {
	@include fluid-margin(
		$min_width,
		$max_width,
		0px,
		0px,
		0px,
		0px,
		0px,
		0px,
		$min_margin_10,
		$max_margin_10
	);
}
.ml_15 {
	@include fluid-margin(
		$min_width,
		$max_width,
		0px,
		0px,
		0px,
		0px,
		0px,
		0px,
		$min_margin_15,
		$max_margin_15
	);
}
.m_left_15 {
	margin-left: 15px !important;
}

.ml_15_t_5 {
	@include fluid-margin(
		$min_width,
		$max_width,
		5px,
		5px,
		0px,
		0px,
		0px,
		0px,
		$min_margin_15,
		$max_margin_15
	);
}
.m_xl_0 {
	margin: 0 !important;
}

.p_20 {
	@include fluid-padding(
		$min_width,
		$max_width,
		$min_padding_20,
		$max_padding_20,
		$min_padding_20,
		$max_padding_20,
		$min_padding_20,
		$max_padding_20,
		$min_padding_20,
		$max_padding_20
	);
}
.p_25 {
	@include fluid-padding(
		$min_width,
		$max_width,
		$min_padding_25,
		$max_padding_25,
		$min_padding_25,
		$max_padding_25,
		$min_padding_25,
		$max_padding_25,
		$min_padding_25,
		$max_padding_25
	);
}
.px_50 {
	@include fluid-padding-double(
		$min_width,
		$max_width,
		0px,
		0px,
		$min_padding_50,
		$max_padding_50
	);
}

.px_40 {
	@include fluid-padding-double(
		$min_width,
		$max_width,
		0px,
		0px,
		$min_padding_40,
		$max_padding_40
	);
}
.pr_20 {
	@include fluid-padding(
		$min_width,
		$max_width,
		0px,
		0px,
		$min_padding_20,
		$max_padding_20,
		0px,
		0px,
		0px,
		0px
	);
}

.pr_30 {
	@include fluid-padding(
		$min_width,
		$max_width,
		0px,
		0px,
		$min_padding_40,
		$max_padding_40,
		0px,
		0px,
		0px,
		0px
	);
}

.pb_30 {
	@include fluid-padding(
		$min_width,
		$max_width,
		0px,
		0px,
		0px,
		0px,
		$min_padding_40,
		$max_padding_40,
		0px,
		0px
	);
}
//Text area
.text_area {
	width: 100%;
	padding: 18px;
	border: 2px solid $light-gray;
	border-radius: 8px;
	font-family: Arial, Helvetica, sans-serif;
	&:hover,
	&:focus,
	&:active {
		border: 2px solid$primary-blue;
		outline: none;
	}

	&::placeholder {
		font-family: Arial, Helvetica, sans-serif;
	}
}
.text_area_short {
	height: 130px;
}

.text_area_shorter {
	height: 85px;
}

.overflow-scroll {
	overflow-y: auto;
	overflow-x: hidden;
	// &__lg {
	//   @include media-breakpoint-up(lg) {
	//     overflow-y: auto;
	//     overflow-x: hidden;
	//   }
	// }
}
// Thumb scrollbar
::-webkit-scrollbar {
	width: 14px;
	background-color: rgba(0, 0, 0, 0.1);
	//border-radius: 15px;
}
::-webkit-scrollbar-thumb,
::-webkit-scrollbar-track-piece {
	background-clip: padding-box;
	border: 3px solid transparent;
	border-radius: 15px;
}
::-webkit-scrollbar-thumb {
	background-color: rgb(172, 172, 172);
}
::-webkit-scrollbar-track-piece {
	background-color: transparent;
}
// Thumb scrollbar
.overflow-hidden {
	overflow-y: hidden;
}

//Main
main {
	height: 100%;
	overflow-x: hidden;
	margin: 15px auto 0;
	max-width: 1660px;
	background-color: $primary-white;
	scrollbar-color: red;
	color: #ff000060;
	&::-webkit-scrollbar {
		width: 0px !important;
	}
}
.main_container_content {
	margin: 0 0.5%;
	background-color: $main-gray;
	height: auto;
	min-height: 100vh;
	border: 1px solid rgba(0, 0, 0, 0.131);
	@media screen and(max-width:450px) {
		margin: 0 5%;
	}
}
.main_section {
	background-color: $primary-white;
	width: 100%;
	height: 100%;
	border-radius: 20px 20px 0px 0px;
	min-height: 100vh;
}
.title_bar {
	@include fluid-padding-double(
		$min_width,
		$max_width,
		$min_padding_25,
		$max_padding_25,
		$min_padding_40,
		$max_padding_40
	);
	width: 100%;
}
.top_section {
	margin-top: 190px;
	// @include fluid-padding-double(
	//   $min_width,
	//   $max_width,
	//   $min_margin_35,
	//   $max_margin_35,
	//   $min_padding_40,
	//   $max_padding_40
	// );

	@include fluid-padding(
		$min_width,
		$max_width,
		$min_margin_35,
		$max_margin_35,
		$min_padding_40,
		$max_padding_40,
		10px,
		15px,
		$min_padding_40,
		$max_padding_40
	);
}

.content_section {
	position: relative;
	@include d_flex(row, none, none);
	width: 100%;
	//padding: 35px 3%;
	@include fluid-padding-double(
		$min_width,
		$max_width,
		$min_margin_35,
		$max_margin_35,
		$min_padding_40,
		$max_padding_40
	);

	aside {
		width: 270px;
		@include fluid-margin(
			$min_width,
			$max_width,
			$min_margin_35,
			$max_margin_35,
			0px,
			0px,
			0px,
			0px,
			0px,
			0px
		);
	}
	// .fixed_aside_inner {
	//   margin-top: 500px;
	// }
	.cards_container {
		width: max-content;
		width: 100%;
	}

	section:last-child {
		width: 245px;
		@include d_flex(column, center, flex-start);
		@include fluid-margin(
			$min_width,
			$max_width,
			$min_margin_35,
			$max_margin_35,
			0px,
			0px,
			0px,
			0px,
			0px,
			0px
		);
	}

	p {
		text-align: center;
	}
	.tags_container {
		width: 100% !important;
	}
}

.form_content_section {
	margin-top: 190px;
	@include fluid-padding-double(
		$min_width,
		$max_width,
		$min_margin_35,
		$max_margin_35,
		$min_padding_40,
		$max_padding_40
	);
	.form_section_two {
		width: 35%;
	}
	.form_section_three {
		width: 30%;

		background: #ffffff 0% 0% no-repeat padding-box;
		box-shadow: 0px 3px 6px #00000029;
		border-radius: 10px;
	}

	@media screen and(max-width:767px) {
		.form_section_two,
		.form_section_three {
			width: 100%;
		}
	}
}

//Misc
.scroll {
	overflow: scroll;
}
//react-date-picker custom styles
.date_input {
	.rdtPicker {
		top: -1400%;
	}
}
.time_input {
	.rdtPicker {
		top: -650%;
	}
}
.rdt {
	input {
		border: none;
		&:hover,
		&:focus,
		&:active {
			border: none;
			outline: none;
		}
	}
}
//react-date-picker custom styles end
//react-select custom styles

.top_bar {
	.react-select-container {
		margin: 0 12px 0 0;
	}
}
.react-select-container {
	width: 100%;
	//z-index: 1;
	@include fluid-margin(
		$min_width,
		$max_width,
		0px,
		0px,
		0px,
		0px,
		$min_margin_25,
		$max_margin_25,
		0px,
		0px
	);
}
.react-select__menu,
.react-select__menu-list {
	z-index: 999999999 !important;
}
.react-select__menu {
	padding-bottom: 30px;
}
//react-select custom styles end
.no_wrap {
	white-space: nowrap;
}
.cursor_pointer {
	cursor: pointer;
}

.cursor_grab {
	cursor: grab;
}
.overflow-scroll {
	overflow: scroll !important;
}
.no-scrollbar {
	&:-webkit-scrollbar {
		width: 0 !important;
		height: 0 !important;
		background-color: transparent;
		border-radius: 0;
	}
}
.border_bottom_thick {
	border-bottom: 2px solid $deep-gray;
}

.border_bottom_thin {
	border-bottom: 1px solid $deep-gray;
}
.mobile_visible {
	display: none !important;
}
.rotate_90 {
	transform: rotate(-90deg);
}
.no_rotate {
	transform: none;
}
.ease {
	transition: all 0.2s ease;
}
.easein {
	animation: fade 1s forwards;
}
.easein-light {
	animation: fade 0.5s forwards;
}
@keyframes fade {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
.quater_width_desktop {
	width: 25%;
}
.second_width_desktop {
	width: 35% !important;
}
.seond_width_desktop_2 {
	width: 45% !important;
}
.half_width_desktop {
	width: 50%;
}

.third_width_desktop {
	width: 60%;
}
.fourth_width_desktop {
	width: 75%;
}
.almost_width,
.rdt input {
	width: 95%;
}
.width_52 {
	width: 52%;
}
.width_full {
	width: 100%;
}
.width_fit {
	width: fit-content;
}
.width_300 {
	width: 320px;
}
.outside_tag_container > div {
	width: 100% !important;
}
//react-tag-autocomplete styles

.app_tag_container {
	position: relative;
	.app_tag {
		background: $primary-white 0% 0% no-repeat padding-box;
		border: 2px solid $light-gray;
		border-radius: 6px;
		color: $primary-black;
		@include fluid-type($min_width, $max_width, $min_font_19, $max_font_19);
		@include fluid-padding-double(
			$min_width,
			$max_width,
			$min_padding_14,
			$max_padding_14,
			$min_padding_20,
			$max_padding_20
		);
		&::placeholder {
			color: $light-gray;
			@include fluid-type($min_width, $max_width, $min_font_18, $max_font_18);
		}
		&:hover,
		&:focus,
		&:active {
			border: 2px solid$primary-blue;
			outline: none;
		}

		.caret {
			path {
				&:hover {
					fill: $transparent-blue;
				}
			}
		}

		.app_tag_drop {
			position: absolute;
			left: 0;
			right: 0;
			top: 35px;
			width: 100%;
			height: fit-content;
			max-height: 120px;
			overflow-y: scroll;
			background-color: $primary-white;
			text-align: left;
			@include fluid-padding-double(
				$min_width,
				$max_width,
				$min_padding_14,
				$max_padding_14,
				$min_padding_20,
				$max_padding_20
			);
			border-radius: 4px;
			box-shadow: 0px 3px 6px #00000029;
			z-index: 999;
			p {
				text-align: left;
				width: 100%;
				margin-bottom: 7px;
				padding: 3px 0;
				cursor: pointer;
				&:hover {
					background-color: $transparent-blue;
				}
			}
		}
	}

	.app_tag_selected {
		flex-flow: wrap;
		flex-wrap: wrap;
		.tag_cap {
			background-color: $transparent-red;
			border-radius: 4px;
			padding: 7px;
			color: $primary-red;

			@include fluid-margin(
				$min_width,
				$max_width,
				0px,
				0px,
				$min_margin_15,
				$max_margin_15,
				$min_margin_15,
				$max_margin_15,
				0px,
				0px
			);

			h6 {
				color: $primary-red;
				font-weight: 400 !important;
			}
			span {
				font-weight: 400 !important;
			}
		}
	}
}
//alert styles
#__react-alert__ {
	z-index: 999999999999999999999999999999 !important;
	div {
		z-index: 999999999999999999999999999999 !important;
	}
}
.app_alert {
	color: $primary-white;
	width: fit-content;
	border-radius: 5px;
	padding: 7px;
	position: relative;

	svg {
		color: $primary-white;
		margin: 0 10px 0 0;
		width: 14px;
		height: 14px;
		transform: scale(1.2);
	}
	.close_alert {
		margin-bottom: -3px;
	}
}
.app_alert_success {
	background-color: #4bb543;
}
.app_alert_error {
	background-color: $danger-red;
}
.app_alert_info {
	background-color: #f7b217;
}
//working days styles
.day_label {
	width: 130px !important;
}
.position_absolute {
	position: absolute;
}
.position_relative {
	position: relative;
}
.fixed_aside_right {
	position: absolute;
	right: 0;
	top: 0px;
	overflow-y: scroll;
	border-left: 2px solid $primary-gray;
	padding: 10px 0 0 10px;
}

.top_fixed {
	position: fixed;
	top: 80px;
	background: $primary-white;
	width: 100%;
	z-index: 99;
}
//Media Queries
@media screen and(max-width:767px) {
	.mobile_visible {
		display: flex !important;
	}
	.desktop_visible {
		display: none !important;
	}
	.half_width_desktop,
	.second_width_desktop,
	.third_width_desktop,
	.quater_width_desktop,
	.fourth_width_desktop,
	.almost_width,
	.width_52,
	.seond_width_desktop_2,
	.rdt input {
		width: 100% !important;
	}
	.tab_column {
		@include d_flex(column, flex-start, space-between);
	}
	.mt_15_tab {
		@include fluid-margin(
			$min_width,
			$max_width,
			$min_margin_15,
			$max_margin_15,
			0px,
			0px,
			0px,
			0px,
			0px,
			0px
		);
	}

	.ml_15_tab {
		@include fluid-margin(
			$min_width,
			$max_width,
			0px,
			0px,
			0px,
			0px,
			0px,
			0px,
			$min_margin_15,
			$max_margin_15
		);
	}

	.mt_35_tab {
		@include fluid-margin(
			$min_width,
			$max_width,
			$min_margin_35,
			$max_margin_35,
			0px,
			0px,
			0px,
			0px,
			0px,
			0px
		);
	}
}

@media screen and(max-width:628px) {
	.mobile_hidden {
		transform: translateX(-120%);
		transition: transform 0.2s ease;
	}
	.slide_out {
		transform: translateX(0);
	}
}

@media screen and(max-width:500px) {
	.mobile_column {
		@include d_flex(column, flex-start, space-between);
	}
	.mt_35_mobile {
		@include fluid-margin(
			$min_width,
			$max_width,
			$min_margin_35,
			$max_margin_35,
			0px,
			0px,
			0px,
			0px,
			0px,
			0px
		);
	}
}
@media screen and(max-width:400px) {
	.ml_15_tab {
		margin-left: 0;
	}
	.mobile_column_two {
		@include d_flex(column, flex-start, space-between);
	}
	.mt_35_mob_two {
		@include fluid-margin(
			$min_width,
			$max_width,
			$min_margin_35,
			$max_margin_35,
			0px,
			0px,
			0px,
			0px,
			0px,
			0px
		);
	}

	.mt_15_mob_two {
		@include fluid-margin(
			$min_width,
			$max_width,
			$min_margin_15,
			$max_margin_15,
			0px,
			0px,
			0px,
			0px,
			0px,
			0px
		);
	}
}
