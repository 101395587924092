@import "../../scss/config";
.meals_list_container {
 padding: 16px;
 border-radius: 20px;
 border: 2px dashed rgb(204, 204, 204);
 //height: 500px;
// overflow-y: scroll;
 display: flex;
 flex-direction: column;
 justify-content: flex-start;
 align-items: flex-start;
 margin-top: 2em;
}
/* Style to rotate icon when state is active */
.rotate {
    transform: rotate(180deg) !important;
  }

  /* Style the accordion content panel. Note: hidden by default */
  .accordion__content {
    overflow-x: hidden;
    transition: max-height 0.3s ease;
  }