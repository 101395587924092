@import "../../scss/config";

.blue_button {
  border: 2px solid$primary-blue;
  &:hover,
  &:focus,
  &:active {
    border: 2px solid$primary-blue;
    background-color: $primary-blue;
    label {
      color: $primary-white;
    }
    path {
      fill: $primary-white;
    }
  }
}
.green_button {
  border: 2px solid $primary-green;
  &:hover,
  &:focus,
  &:active {
    border: 2px solid $primary-green;
    background-color: $primary-green;
    label {
      color: $primary-white;
    }
  }
}

.red_button {
  border: 2px solid $danger-red;
  &:hover,
  &:focus,
  &:active {
    border: 2px solid $danger-red;
    background-color: $danger-red;
    label {
      color: $primary-white;
    }
  }
}

.button {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 7.5px;
  background-color: $primary-white;
  @include fluid-padding-double(
    $min_width,
    $max_width,
    $min_padding_14,
    $max_padding_14,
    $min_padding_20,
    $max_padding_20
  );
  //  padding: 14px 20px;
  height: fit-content;
  width: fit-content;
  @include d_flex(row, center, center);
  transition: all 0.2s ease;
  label {
    white-space: nowrap;
  }
}
.round_button {
  @include d_flex(row, center, center);
  background-color: $primary-white;
  border: 2px solid $primary-green;
  border-radius: 50%;
  @include fluid-padding-double($min_width, $max_width, 3px, 4px, 5px, 7px);
  height: fit-content;
  width: fit-content;
  transition: all 0.2s ease;
  border: 2px solid $primary-green;
  &:hover,
  &:focus,
  &:active {
    border: 2px solid $primary-green;
    background-color: $primary-green;
    label {
      color: $primary-white;
    }
  }
  span {
    height: fit-content;
    width: fit-content;
  }
  .sub_icon {
    margin-left: auto;
  }
}
