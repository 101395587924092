/** @format */

.polygon_item {
	display: flex;
	align-items: center;
	margin-bottom: 15px;
	width: 90%;

	label {
		display: flex;
		flex-grow: 1;
	}

	.edit-txt {
		color: red;
		font-size: 9px;
		text-decoration: underline;
		font-weight: bold;
		cursor: pointer;
	}
}
