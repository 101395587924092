.select_width {
	width: 180px;
}
.radio_class {
	display: grid;
	grid-template-columns: repeat(4, 3fr);
	gap: 8px;
}
.parser_container {
}
// .small_content {
// 	display: flex;
// }
.new_addon_container {
	margin: 0px 84px 0px 84px;
}

.radio_flex {
	display: flex;
	flex-wrap: wrap;
	grid-column-gap: 2rem;
	grid-row-gap: 1rem;
}



.new_nav{
    margin-left: 26px;
    margin-top: 10px;
  
}

.meal_category_link{
	text-decoration: underline;
	cursor:pointer
}

.info_important p{
    margin:.5rem 1rem .5rem .5rem;
    text-align: left;
}
.info_important h5{
    margin-top:1em;
    text-align: left;
	height: 2em;
}
.info_important{
    background-color: rgba(247, 202, 167, 0.866);
  border-radius: 8px;
  margin-bottom: 1em;
    color:black;
    font-size: 12px;
    text-align: left;
    font-weight: regular;
    padding-top: .3em;
    padding-bottom: .3em;
	padding-left: 10px;
    display:inline-block
   
}

.no-scroll{
    overflow: visible!important ;
}




