.image_card {
  height: 100px;
  width: 100%;
  position: relative;
  &::after {
    content: "";
    top: 0;
    left: 0;
    height: 100px;
    width: 100%;
    background: rgba(0, 0, 0, 0.393);
    position: absolute;
  }
  div {
    width: 100%;
    height: 100px;
  }
  figcaption {
    position: absolute;
    bottom: 25px;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 99;
  }
}
