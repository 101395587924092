@import "../../scss/config";

.deal_card {
	border-radius: 4px;
	background: $primary-white;
	box-shadow: 0px 3px 6px #00000029;
	width: 100%;
	height: fit-content;
	position: relative;

	.post_card_image {
		width: 100%;
		height: 98px;
		border-radius: 4px 4px 0 0;
	}
	.time_span {
		border-radius: 3.5px;
		padding: 3px 7px;
		box-shadow: 0px 2.5px 6px #00000029;
		background: $primary-white;
		position: absolute;
		top: 10px;
		right: 10px;
	}
	.card_captions {
		height: fit-content;
		background: $primary-white;
		padding: 10px 10px 17px 11px;
		border-radius: 0 0 4px 4px;
		img {
			@include fluid-width($min_width, $max_width, 20px, 30px);
			@include fluid-height($min_width, $max_width, 20px, 30px);
		}
		label {
			white-space: nowrap;
		}
	}
}
.image_placeholder {
	height: 213.28px !important;
	.brand_logo_placeholder {
		@include fluid-width($min_width, $max_width, 35px, 45px);
		@include fluid-height($min_width, $max_width, 35px, 45px);
		background-color: #e6e6e6;
		margin-left: 5%;
		border-radius: 3px;
		animation: fading 1.5s linear infinite;
	}
	.title_placeholder {
		width: 80%;
		@include fluid-height($min_width, $max_width, 15px, 18px);
		background-color: #e6e6e6;
		margin: 0 5% 5px auto;
		border-radius: 3px;
		animation: fading 1.5s linear infinite;
	}
	.name_placeholder {
		width: 50%;
		margin: 0 auto 0 15%;
		@include fluid-height($min_width, $max_width, 15px, 18px);
		background-color: #e6e6e6;
		border-radius: 3px;
		animation: fading 1.5s linear infinite;
	}

	.body_placeholder {
		width: 90%;
		margin: 30px auto 0;
		@include fluid-height($min_width, $max_width, 15px, 18px);
		background-color: #e6e6e6;
		border-radius: 3px;
		animation: fading 1.5s linear infinite;
	}
	.top_body_placeholder {
		width: 90%;
		margin: 30px auto 5px;
		@include fluid-height($min_width, $max_width, 15px, 18px);
		background-color: #e6e6e6;
		border-radius: 3px;
		animation: fading 1.5s linear infinite;
	}
	.time_span {
		background-color: #e6e6e6;
		width: 70px;
		box-shadow: none;
		height: 16px;
		animation: fading 1.5s linear infinite;
	}
}

.feed {
	width: 250px;
	.card_captions .card_flex {
		display: flex;
		align-items: flex-start;
	}

	.name {
		font-size: 0.95rem;
		font-weight: 600;
	}

	.subtitle {
		font-size: 0.8rem;
		font-weight: normal;
	}

	.view-btn {
		margin-top: 1rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		cursor: pointer;

		button {
			padding: 0.3rem;
			h6 {
				font-size: 0.7rem;
			}
		}
	}

	label {
		text-decoration: underline;
		text-decoration-color: #ccc;
		text-underline-offset: 2px;
	}
}
